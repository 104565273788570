#root {
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow: overlay;
}
#root::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
#root::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #eeeeee;
  cursor: pointer;
}
#root::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}
#root::-webkit-scrollbar-thumb:active {
  background: #b0b0b0;
}
.custom-ql-lg .ql-editor {
  overflow: auto;
  overflow: overlay;
  border: 1px solid #d9d9d9;
  font-size: 16px;
  line-height: 2em;
}
.custom-ql-lg .ql-editor::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.custom-ql-lg .ql-editor::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #eeeeee;
  cursor: pointer;
}
.custom-ql-lg .ql-editor::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}
.custom-ql-lg .ql-editor::-webkit-scrollbar-thumb:active {
  background: #b0b0b0;
}
.page-wrapper {
  max-width: 1434px;
  width: 98%;
  margin: 0 auto;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clear {
  clear: both;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-drawer-body {
  overflow: auto;
  overflow: overlay;
}
.ant-drawer-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ant-drawer-body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #eeeeee;
  cursor: pointer;
}
.ant-drawer-body::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}
.ant-drawer-body::-webkit-scrollbar-thumb:active {
  background: #b0b0b0;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
